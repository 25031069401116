(function () {
  var state = document.readyState;
  if (state === 'interactive' || state === 'complete') {
    if (document.querySelector('.panels-ipe-sort-container')) {
      // take out the first panel and put it to the top
      document.getElementById("panels-ipe-regionid-contentmain").prepend(document.querySelector('.panels-ipe-portlet-wrapper:first-of-type'));
      // the rest of the panels should be in a flex container
      document.querySelector('.panels-ipe-sort-container').classList.add('flex');
    }
  } else setTimeout(arguments.callee, 100);
})();