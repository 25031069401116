import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
(function ($) {
  $(document).ready(function () {
    if (document.documentElement.clientWidth < 1024) {
      var width = '100%';
      var height = '100%';
    } else {
      var width = '85%';
      var height = '85%';
    }
    // check the called url for a campaign hash (id) on page load
    if (window.location.hash != undefined && window.location.hash != '' && window.location.hash != '#more-links') {
      // get the called campaign hash (has still the hash sign, remove it)
      var current_campaign = window.location.hash.replace('#', '');
      show_campaign(current_campaign);
    }
    function show_campaign(current_campaign) {
      if (current_campaign.delegateTarget != undefined && current_campaign.delegateTarget.id != '') {
        current_campaign = current_campaign.delegateTarget.id;
      }
      // check if we find a campaign with this hash on the page
      if (current_campaign != '') {
        // get the hidden html content of the campaign
        var output_html = $('#colorbox-' + current_campaign).html();
        // we have found the campaign, so open the data in colorbox
        $.colorbox({
          html: output_html,
          width: width,
          height: height,
          fixed: true,
          escKey: true,
          opacity: 0.6
        });
      } else {
        return false;
      }
    }
    function show_campaign_text() {
      if ($('#campaign-text').length) {
        // get the hidden html content of the campaign
        var output_html = $('#campaign-text').html();
        // we have found the campaign, so open the data in colorbox
        $.colorbox({
          html: output_html,
          width: width,
          height: height,
          fixed: true,
          opacity: 0.6
        });
      }
      return false;
    }

    //#campaign-text
    $('.btn-campaign').on('click', show_campaign_text);
    $('.campaign').on('click', show_campaign);
  });
})(jQuery);