import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
// Default theme
import '@splidejs/splide/css';

// or only core styles
//import '@splidejs/splide/css/core';

import Splide from '@splidejs/splide';
window.splide = {};
document.addEventListener('DOMContentLoaded', function () {
  // loop trough li elements and add class "splide__slide" (because its changed from the view)
  var splide_li = document.querySelectorAll('.splide__list li');
  splide_li.forEach(function (element) {
    element.classList.add('splide__slide');
  });
  var splide = new Splide('.splide', {
    type: 'fade',
    pagination: true,
    autoplay: true,
    interval: 5000,
    speed: 1500,
    rewind: true,
    arrows: false,
    drag: true,
    arrowPath: 'M 11.366129,39.999973 A 2.7213257,2.7213257 0 0 1 9.4387758,35.345233 L 24.784871,19.999137 9.4387758,4.6530422 A 2.725689,2.725689 0 1 1 13.293482,0.79833585 L 30.566931,18.071784 a 2.7213257,2.7213257 0 0 1 0,3.854707 L 13.293482,39.199939 a 2.7152649,2.7152649 0 0 1 -1.927353,0.800034 z'
  }).mount();
});